<template lang="pug">
  .index-w
    <routerView/>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.index-w {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
}
</style>
